import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { take } from 'rxjs/operators';
import { format } from 'date-fns/format';

import { AlertAppService } from './alert-app.service';
import { UsuarioAppService } from './usuario-app.service';
import { UsuarioModel } from 'src/models/usuarioModel';
import { ControleLoginAppService } from './controle-login-app.service';
import { FuncoesGeraisAppService } from 'src/services/funcoes-gerais-app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAppService {
  _proxRoute: string = '';

  constructor(
    private router: Router,
    private alertAppSrv: AlertAppService,
    private usuarioAppSrv: UsuarioAppService,
    private controleLoginAppSrv: ControleLoginAppService,
    private funcoesGeraisAppSrv: FuncoesGeraisAppService,
  ) { }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const _roles = next.data['roles'] as Array<string>;
    // console.log(_roles)
    // console.log(state.url)
    if (state.url.indexOf('seleciona_regiao') == -1) {
      this.funcoesGeraisAppSrv.defineFlagReclameAqui(false);
    }
    if (_roles[0] == 'livre') {
      return true;
    }
    // console.log('continuou')
    let _tpLogin = '';
    if (localStorage.getItem('PasseioJaApp:tpLogin')) {
      _tpLogin = localStorage.getItem('PasseioJaApp:tpLogin') as string;
    }
    // links de pedido precisam ser ajustados conforme o usuario que estiver logado
    if (_tpLogin == 'guia'
      && state.url.indexOf('tabs/tabClientePedidos/pedidoDetalheCliente') > -1) {
      let _rotaAjustada = state.url.replace('tabs/tabClientePedidos/pedidoDetalheCliente', 'tabs/tabGuiaHome/pedidoDetalheGuia');
      console.log('rota ajustada - ', _rotaAjustada);
      return this.router.navigateByUrl(_rotaAjustada);
    }
    if (_tpLogin == 'cliente'
      && state.url.indexOf('tabs/tabGuiaHome/pedidoDetalheGuia') > -1) {
      let _rotaAjustada = state.url.replace('tabs/tabGuiaHome/pedidoDetalheGuia', 'tabs/tabClientePedidos/pedidoDetalheCliente');
      console.log('rota ajustada - ', _rotaAjustada);
      return this.router.navigateByUrl(_rotaAjustada);
    }
    if (_tpLogin == '') {
      // salva o link para onde ia, para ser direcionado após o login
      this.alertAppSrv.toast(`Necessário Login`);
      localStorage.setItem('PasseioJaApp:proxRoute', state.url);
      const currentNavigation = this.router.getCurrentNavigation();
      if (currentNavigation && currentNavigation.extras && currentNavigation.extras.state) {
        // console.log('extras', currentNavigation.extras.state)
        localStorage.setItem('PasseioJaApp:extras', JSON.stringify(currentNavigation.extras.state));
      }
      return this.router.navigateByUrl('/tabs/login');
    }
    // rotina abaixo serve para garantir a seguinte situação:
    // Se outro usuário abriu outra janela fez o login com um usuário diferente,
    // ao voltar na janela original vai fazer teste sempre que navegar
    // para outra página e comparar o conteúdo deste observable (uid do menu original)
    // x localstorage (salvo na segunda janela).
    let _uidMenu: string = '';
    const sub = this.controleLoginAppSrv.observableUidUsuarioLogged().pipe(take(1)).subscribe(uid =>
      _uidMenu = uid
    );
    sub.unsubscribe();
    // console.log(_uidMenu);
    let _uidUsuarioLocalStorage: string = '';
    if (localStorage.getItem('PasseioJaApp:uid')) {
      _uidUsuarioLocalStorage = localStorage.getItem('PasseioJaApp:uid') as string;
      if (_uidMenu
        && _uidMenu != _uidUsuarioLocalStorage) {
        this.controleLoginAppSrv.Logout();
        this.alertAppSrv.toast(`Necessário novo login devido outra janela com outro usuário`);
        localStorage.setItem('PasseioJaApp:proxRoute', state.url);
        return this.router.navigateByUrl('/tabs/login');
      }
    } else {
      // console.log('está sem localstorage')
      this.controleLoginAppSrv.Logout();
      this.alertAppSrv.toast(`Necessário novo login`);
      localStorage.setItem('PasseioJaApp:proxRoute', state.url);
      return this.router.navigateByUrl('/tabs/login');
    }
    // verifica localmente se vai expirar o login
    if (localStorage.getItem('PasseioJaApp:dhExpiracao')) {
      const _dhAtualStr = format(new Date(), 'yyyyMMddHHmmss');
      const _dhExpiracao = localStorage.getItem('PasseioJaApp:dhExpiracao') as string;
      if (_dhAtualStr > _dhExpiracao) {
        let _retirouLogin = this.controleLoginAppSrv.Logout();
        if (_retirouLogin) {
          this.alertAppSrv.toast(`Login expirado`);
          localStorage.setItem('PasseioJaApp:proxRoute', state.url);
          return this.router.navigateByUrl('/tabs/login');
        }
      }
    } else {
      this.alertAppSrv.toast('Necessário novo Login');
      if (localStorage.getItem('PasseioJaApp:tpLogin')) {
        localStorage.removeItem('PasseioJaApp:tpLogin');
      }
      localStorage.setItem('PasseioJaApp:proxRoute', state.url);
      return this.router.navigateByUrl('/tabs/login');
    }
    if (_roles.indexOf(_tpLogin) < 0) {
      this.alertAppSrv.toast(`Tipo de Login não permite o acesso solicitado - rota ${state.url}`);
      return this.router.navigateByUrl('');
    }
    if (_uidUsuarioLocalStorage !== '') {
      let _usuarioLogin: UsuarioModel = await this.TentativasObterUsuario(_uidUsuarioLocalStorage);
      if (_usuarioLogin) {
        if (!_usuarioLogin.uid) {
          this.alertAppSrv.toast('Necessário novo Login');
          localStorage.setItem('PasseioJaApp:proxRoute', state.url);
          return this.router.navigateByUrl('/tabs/login');
        } else {
          if (_usuarioLogin.uid != _uidUsuarioLocalStorage) {
            this.alertAppSrv.toast('Necessário novo Login');
            localStorage.setItem('PasseioJaApp:proxRoute', state.url);
            return this.router.navigateByUrl('/tabs/login');
          } else {
            return true;
          }
        }
      } else {
        this.alertAppSrv.toast('Necessário novo Login');
        localStorage.setItem('PasseioJaApp:proxRoute', state.url);
        return this.router.navigateByUrl('/tabs/login');
      }
    } else {
      this.alertAppSrv.toast('Necessário novo Login');
      localStorage.setItem('PasseioJaApp:proxRoute', state.url);
      return this.router.navigateByUrl('/tabs/login');
    }
  }

  // a principio ja deveria ter o objeto Usuario, pois na tabs.page ja verifica essa
  // situacao (ter localStorage do uid e token, mas nao ter objetos usuario, ...)
  // e ja manda buscar na API
  // porem quando já inicia numa url que exige login não da tempo da tabs.page.ts processar
  async TentativasObterUsuario(uidUsuarioLocalStorage: string): Promise<UsuarioModel> {
    let _usuarioLogado: UsuarioModel = this.controleLoginAppSrv.ObterUsuarioLogado();
    if (_usuarioLogado && _usuarioLogado.uid) {
      return _usuarioLogado;
    } else {
      let _usuarioLogadoAPI = await this.usuarioAppSrv.ObterLogadoAPI(uidUsuarioLocalStorage);
      if (_usuarioLogadoAPI) {
        return _usuarioLogadoAPI;
      } else {
        return new UsuarioModel();
      }
    }
  }

}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  return inject(AuthGuardAppService).canActivate(next, state);
}
